<template>
  <!-- Master Card -->
  <a-card class="card-credit">
    <!-- <a-card class="card-credit header-solid h-full"> -->

    <div style="margin-left: 14px">
      <div class="mr-30">
        <p>กระเป๋าเงิน</p>
      </div>
      <h2 class="m-0"><Balance /></h2>

      <div class="card-footer">
        <!-- <div class="mr-30">
          <p>Username</p>
          <h6>{{ getUsername }}</h6>
        </div> -->
        <!-- <div class="mr-30">
          <p>เวลาปัจจุบัน</p>
          <h6>{{ timestamp }}</h6>
        </div> -->
        <div class="card-footer-col col-logo ml-auto">
          <p>Username</p>
          <h6>{{ getUsername }}</h6>
        </div>
      </div>
    </div>
  </a-card>
  <!-- / Master Card -->
</template>

<script>
import Balance from "@/views/components/wallet/Balance.vue";

export default {
  components: {
    Balance,
  },
  computed: {
    getUsername: function () {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    // displayTime: function () {
    //   return setInterval(this.refreshTime, 1000);
    // },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  data() {
    return { timestamp: "loading..." };
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        (today.getHours().toString().length != 2 ? "0" : "") +
        today.getHours() +
        ":" +
        (today.getMinutes().toString().length != 2 ? "0" : "") +
        today.getMinutes() +
        ":" +
        (today.getSeconds().toString().length != 2 ? "0" : "") +
        today.getSeconds();
      const dateTime = time + " น.";
      this.timestamp = dateTime;
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 449px) {
  .card-credit {
    width: 100%;
    height: 120px;

    p {
      font-size: 12px;
      margin: 0;
    }
    h2 {
      font-size: 24px;
      margin: 0;
    }
    .card-footer-col {
      p {
        font-size: 10px;
        margin: 0;
      }
      h6 {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
</style>
