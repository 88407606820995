<template>
  <!-- Master Card -->
  <div class="card-main">
    <a-card
      class="card-game"
      :style="`background-image:${image}`"
      style="height: 130px; cursor: pointer"
    >
      <!-- <a-card class="card-credit header-solid h-full"> -->

      <a-col :span="7"> </a-col>
      <div style="margin-left: -14px">
        <h4
          class="m-1"
          style="
            background-image: linear-gradient(to right, #ff0000, #ff000000);
            width: 100%;
          "
        >
          {{ title }}
        </h4>

        <!-- <div class="card-footer">
        <div class="card-footer-col col-logo ml-auto">
          <img src="images/logos/mastercard-logo.png" />
        </div>
      </div> -->
      </div>
    </a-card>
    <div class="status center">
      <a-badge
        v-if="data.status != '' && data.tag != ''"
        class="mb-0"
        style="margin-top: 5px"
        :status="data.tag"
        :text="data.status"
      />
      <pulse-loader
        style="padding: 3px 0px 3px 0px"
        :color="'#000000'"
        :size="'4px'"
        v-else
      />
    </div>
  </div>
  <!-- / Master Card -->
</template>

<script>
import Balance from "@/views/components/wallet/Balance.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  props: {
    title: {
      type: String,
    },
    image: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  components: {
    Balance,
    PulseLoader,
  },
  computed: {
    getUsername: function () {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    // displayTime: function () {
    //   return setInterval(this.refreshTime, 1000);
    // },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  data() {
    return { timestamp: "loading..." };
  },
  methods: {},
};
</script>
<style lang="scss">
.card-game {
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 8px 8px 0px 0px !important;
}

.card-main {
  transition: all 0.3s ease;
}

.card-main:hover {
  transform: translateY(-20px);
}

.status {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border-radius: 0px 0px 8px 8px;
  padding: 4px;
  span {
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
}
</style>
