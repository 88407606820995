<!-- 
  This is the billing page, it uses the dashboard layout in: 
  "./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <Loading :loading="false" />
    <!-- <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul> -->
    <a-row type="flex" :gutter="24">
      <!-- Billing Info Column -->

      <a-col :span="24" :md="24">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :xl="12" class="mb-24">
            <!-- Master Card -->
            <Wallet></Wallet>
            <!-- / Master Card -->
          </a-col>
          <a-col :span="24" :md="24" :xl="12" class="mb-24">
            <a-col class="col-content" :span="24" :xl="8">
              <Chart :height="151" :gameType="`BACCARAT`" :resize="true" />
            </a-col>

            <a-col class="col-content" :span="24" :xl="8">
              <ChartRoulette
                :height="210"
                :gameType="`ROULETTE`"
                :resize="true"
              />
            </a-col>

            <a-col class="col-content" :span="24" :xl="8">
              <Chart :height="151" :gameType="`DRAGON_TIGER`" :resize="true" />
            </a-col>
          </a-col>
          <!-- <a-col :span="24" :xl="12" class="mb-24">
            <a-card :bordered="false"
              ><h4 class="m-1">เลือกเกม</h4>
              <a-button
                type="primary"
                block
                class="mb-10"
                v-for="(data, i) in games"
                :key="i"
                @click="next(data.path)"
              >
                <a-row type="flex" :gutter="24">
                  <a-col :span="6">
                    <svg
                      width="26"
                      height="26"
                      fill="#ffffff"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M17,4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2ZM5.5,7a1,1,0,1,1,1-1A1,1,0,0,1,5.5,7Zm4,7c-1,1-3,2-3-1s3-4,3-4,3,1,3,4S10.5,15,9.5,14Zm4,5a1,1,0,1,1,1-1A1,1,0,0,1,13.5,19Z"
                        ></path>
                        <path
                          d="M20,3H19V20a2,2,0,0,1-2,2h3a2,2,0,0,0,2-2V5A2,2,0,0,0,20,3Z"
                        ></path>
                      </g>
                    </svg>
                  </a-col>

                  <a-col :span="11">
                    <h6 class="mb-0" style="margin-top: 5px">
                      {{ data.title }}
                    </h6>
                  </a-col>
                  <a-col :span="7">
                    <div class="status">
                      <a-badge
                        v-if="data.status != '' && data.tag != ''"
                        class="mb-0"
                        style="margin-top: 5px"
                        :status="data.tag"
                        :text="data.status"
                      />
                      <pulse-loader
                        style="padding: 3px 0px 3px 0px"
                        :color="'#000000'"
                        :size="'4px'"
                        v-else
                      />
                    </div>
                  </a-col>
                </a-row>
              </a-button>
            </a-card>
          </a-col> -->
          <a-col :span="24" :md="24" class="mb-24">
            <a-col
              v-for="(data, i) in games"
              class="col-content p-0 pr-10"
              :key="i"
              :span="24"
              :xl="8"
              @click="next(data.path)"
            >
              <GameButton
                :title="data.title"
                :data="data"
                :image="`url('images/${i + 1}.png')`"
                class="mb-10"
              />
            </a-col>

            <!-- / Invoices Card -->
          </a-col>
          <a-col :span="24" class="mb-24">
            <template>
              <!-- Information Card 1 -->
              <a-card :bordered="false" class="card-info mb-24">
                <a-row type="flex">
                  <a-col class="col-content" :span="24" :xl="12">
                    <div class="card-content">
                      <h6>ระบบทดลองเล่น</h6>
                      <h5>SUPERBOT</h5>
                      <p>
                        ท่านผู้ใช้งาน สามารถสร้างบัญชีจำลองเพื่อทดลองเล่นได้ก่อน
                        โดยระบบจะมีชิปจำลองให้สามารถทดลองใช้บอท
                        และสามารถรีเซ็ทชิปใหม่ได้ไม่จำกัด
                      </p>
                    </div>
                    <div class="card-footer">
                      <a-button
                        type="primary"
                        @click="mockSwitch"
                        v-if="!this.$store.getters.getMock"
                      >
                        <span>กดเพื่อทดลองเล่น</span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z"
                            fill="#111827"
                          />
                        </svg>
                      </a-button>
                      <a-button type="primary" disabled v-else>
                        <span style="color: grey">อยู่ในโหมดทดลองเล่น</span>
                      </a-button>
                    </div>
                  </a-col>
                  <!-- <a-col class="col-img" :span="24" :xl="12">
                    <div class="card-img-bg">
                      <img
                        src="images/info.jpg"
                        alt=""
                        style="width: 100%; border-radius: 10px"
                      />
                    </div>
                  </a-col> -->
                </a-row>
              </a-card>
              <!-- / Information Card 1 -->
            </template>
            <!-- <a-card :bordered="false">
              <img
                src="images/info.jpg"
                alt=""
                style="width: 100%; border-radius: 10px"
              />
            </a-card> -->
          </a-col>

          <!-- / Invoices Column -->
        </a-row>
      </a-col>
      <!-- / Billing Info Column -->
    </a-row>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import Wallet from "./components/wallet/Wallet";
import CardInfo from "../components/Cards/CardInfo";
import Loading from "./components/Loading.vue";
import Chart from "./components/chart/Chart.vue";
import gameList from "@/views/json/gameList.json";
import GameButton from "./components/GameButton.vue";
import ChartRoulette from "./components/chart/ChartRoulette.vue";

export default {
  components: {
    Wallet,
    CardInfo,
    Loading,
    Chart,
    PulseLoader,
    GameButton,
    ChartRoulette,
  },
  mounted() {
    //this.games = gameList;
    for (let i = 0; i < gameList.length; i++) {
      this.games.push({ ...gameList[i], status: "", tag: "" });
    }
    for (let i = 0; i < this.games.length; i++) {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${gameList[i].path}/current?isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          if (data.status === "IDLE") {
            this.games[i].status = "Online";
            this.games[i].tag = "success";
          }
          if (data.status === "RUNNING") {
            this.games[i].status = "กำลังเล่น";
            this.games[i].tag = "processing";
          }

          if (data.status === "PAUSED") {
            this.games[i].status = "พักบอท";
            this.games[i].tag = "default";
          }
        })
        .catch((err) => {
          this.games[i].status = "Offline";
          this.games[i].tag = "error";
          return err;
        });
    }
  },
  data() {
    return {
      games: [],
    };
  },
  methods: {
    next(val) {
      this.$router
        .push({
          path: `/room/${val}`,
          params: {
            botType: val,
          },
        })
        .catch(() => {});
    },

    mockSwitch() {
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/api/me/mock/switch`, {
          isMock: !this.$store.getters.getMock,
        })
        .then(({ data }) => {
          this.$store
            .dispatch("setMock", !this.$store.getters.getMock)
            .then(() => {
              window.location.reload();
            });
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
